import React from "react";
import { Card } from "@mui/material";
import ProjectCards from "./ProjectCards";
import react from '../image/react.jpg';
import seo from '../image/seo.jpg';
import wp from '../image/wp.jpg';
import cw from '../image/cw.jpg';
import ecom from '../image/ecom.jpg';
import Footer from "../Footer";

const Services = () => {
    return (
        <>
            <div className=" mt-[100px] md:mt-[230px]"> {/* Adjusted margin for top spacing */}
                <h5 className="text-2xl text-center font-bold">Popular IT Services</h5>
                <h1 className="text-4xl text-center font-bold">Solutions For IT Business</h1>
            </div>
            <br />
            <div className="flex flex-wrap justify-center gap-9 p-4">
                 {/* React E-commerce Solutions Card */}
                <Card className="group w-full sm:w-80 md:w-72 lg:w-64 xl:w-80 h-auto shadow-lg rounded-lg overflow-hidden transition-transform duration-300 transform hover:scale-105">
                    <div className="relative w-full">
                        <img src={react} alt="Content-writing" className="w-full h-50 object-cover mb-4" />
                        <div className="p-4 bg-white text-center group-hover:bg-[#059212] group-hover:text-white transition-all duration-300">
                            <h1 className="text-2xl font-bold">React E-commerce Solutions</h1>
                            <p className="text-lg mt-2 text-gray-600 group-hover:text-white">
                                Build fast, scalable, and efficient e-commerce websites using React. 
                                We create customized online stores that enhance user experience.
                            </p>
                        </div>
                    </div>
                </Card>
                {/* Custom Web Development Service Card */}
                    <Card className="group w-full sm:w-80 md:w-72 lg:w-64 xl:w-80 h-auto shadow-lg rounded-lg overflow-hidden transition-transform duration-300 transform hover:scale-105">
                    <div className="relative w-full">
                        <img src={ecom} alt="ecom" className="w-full h-50 object-cover mb-4" />
                        <div className="p-4 bg-white text-center group-hover:bg-[#059212] group-hover:text-white transition-all duration-300">
                            <h1 className="text-2xl font-bold">Custom Web Development</h1>
                            <p className="text-lg mt-2 text-gray-600 group-hover:text-white">
                                We offer custom web development services to create tailored solutions 
                                for your business, ensuring an intuitive user experience and powerful backend features.
                            </p>
                        </div>
                    </div>
                </Card>

                 {/* WordPress Development Service Card */}
                 <Card className="group w-full sm:w-80 md:w-72 lg:w-64 xl:w-80 h-auto shadow-lg rounded-lg overflow-hidden transition-transform duration-300 transform hover:scale-105">
                    <div className="relative w-full">
                        <img src={wp} alt="wordpress" className="w-full h-50 object-cover mb-4" />
                        <div className="p-4 bg-white text-center group-hover:bg-[#059212] group-hover:text-white transition-all duration-300">
                            <h1 className="text-2xl font-bold">WordPress Development</h1>
                            <p className="text-lg mt-2 text-gray-600 group-hover:text-white">
                                Leverage the power of WordPress to build a responsive and feature-rich website. 
                                We customize WordPress themes and plugins to suit your business needs.
                            </p>
                        </div>
                    </div>
                </Card>
                
                {/* SEO Service Card */}
                <Card className="group w-full sm:w-80 md:w-72 lg:w-64 xl:w-80 h-auto shadow-lg rounded-lg overflow-hidden transition-transform duration-300 transform hover:scale-105">
                    <div className="relative w-full">
                        <img src={seo} alt="SEO" className="w-full h-50 object-cover mb-4" />
                        <div className="p-4 bg-white text-center group-hover:bg-[#059212] group-hover:text-white transition-all duration-300">
                            <h1 className="text-2xl font-bold">SEO Services</h1>
                            <p className="text-lg mt-2 text-gray-600 group-hover:text-white">
                                Improve your website's visibility with our expert SEO services. 
                                We optimize your content to rank higher in search engines, driving organic traffic.
                            </p>
                        </div>
                    </div>
                </Card>

                {/* Content Writing Service Card */}
                <Card className="group w-full sm:w-80 md:w-72 lg:w-64 xl:w-80 h-auto shadow-lg rounded-lg overflow-hidden transition-transform duration-300 transform hover:scale-105">
                    <div className="relative w-full">
                        <img src={cw} alt="Content-writing" className="w-full h-50 object-cover mb-4" />
                        <div className="p-4 bg-white text-center group-hover:bg-[#059212] group-hover:text-white transition-all duration-300">
                            <h1 className="text-2xl font-bold">Content Writing</h1>
                            <p className="text-lg mt-2 text-gray-600 group-hover:text-white">
                                We offer professional content writing services to engage your audience 
                                and provide valuable information that resonates with your brand.
                            </p>
                        </div>
                    </div>
                </Card>

               

               

                
            </div>

            <ProjectCards/>
            <Footer/>
        </>
    );
};

export default Services;
