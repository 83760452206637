import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";

const Dashboard = () => {
  const [appointments, setAppointments] = useState([]);
  const [formSubmissions, setFormSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Log the API calls
        console.log("Fetching appointments and form submissions...");

        // Fetch data from the API
        const [appointmentsResponse, formsResponse] = await Promise.all([
          axios.get("https://www.codexialab.com/get_appointments.php"), // Endpoint for appointments
          axios.get("https://www.codexialab.com/get_form_submissions.php"), // Endpoint for form submissions
        ]);

        // Log the responses
        console.log("Appointments Response:", appointmentsResponse.data);
        console.log("Form Submissions Response:", formsResponse.data);

        // Set the form submission data to the state
        setAppointments(appointmentsResponse.data.data || []);
        setFormSubmissions(formsResponse.data.data || []);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return <Typography variant="h6" align="center">Loading dashboard data...</Typography>;
  }

  return (
    <div className="mt-8">
      <Typography variant="h4" align="center" gutterBottom>
        Admin Dashboard
      </Typography>

      <div className="mt-4 mb-8">
        <Paper elevation={3} className="p-4">
          <Typography variant="h5" gutterBottom>
            Appointment Bookings
          </Typography>
          {appointments.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><b>Id</b></TableCell>
                    <TableCell><b>Name</b></TableCell>
                    <TableCell><b>Email</b></TableCell>
                    <TableCell><b>Date</b></TableCell>
                    <TableCell><b>Time</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointments.map((appointment, index) => (
                    <TableRow key={index}>
                      <TableCell>{appointment.id}</TableCell>
                      <TableCell>{appointment.name}</TableCell>
                      <TableCell>{appointment.email}</TableCell>
                      <TableCell>{appointment.date}</TableCell>
                      <TableCell>{appointment.time}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No appointments booked yet.</Typography>
          )}
        </Paper>
      </div>

      <div className="mt-4 mb-8">
        <Paper elevation={3} className="p-4">
          <Typography variant="h5" gutterBottom>
            Contact Form Submissions
          </Typography>
          {formSubmissions.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><b>Id</b></TableCell>
                    <TableCell><b>Full Name</b></TableCell>
                    <TableCell><b>Email</b></TableCell>
                    <TableCell><b>Phone</b></TableCell>
                    <TableCell><b>Subject</b></TableCell>
                    <TableCell><b>Message</b></TableCell>
                    <TableCell><b>Submitted At</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formSubmissions.map((submission, index) => (
                    <TableRow key={index}>
                      <TableCell>{submission.id}</TableCell>
                      <TableCell>{submission.fullName}</TableCell>
                      <TableCell>{submission.email}</TableCell>
                      <TableCell>{submission.phone}</TableCell>
                      <TableCell>{submission.subject}</TableCell>
                      <TableCell>{submission.message}</TableCell>
                      <TableCell>{new Date(submission.submitted_at).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No form submissions yet.</Typography>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default Dashboard;
