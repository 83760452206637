import React, { useState } from "react";
import {  Link } from "react-router-dom";
import { Container, Grid,Button } from "@mui/material";
import Lottie from "react-lottie";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import update from "../image/update"; // Replace with your animation file

import CM from "../image/cm.jpg"; // Replace with your animation file
import Footer from "../Footer" ;


const About = () => {
  

  const up = {
    loop: true,
    autoplay: true,
    animationData: update,
  };


  return (
    <>
    <section className="bg-gray-100 py-20 ">
      <Container>
        {/* Section 1: Left Content, Right Image */}
        <div className="mb-16 ">
          <h1 className=" text-[37px]  mt-[-50px] md:mt-[0px] sm:text-4xl text-center font-bold text-gray-800">
            About Codexialab
          </h1>
          <Grid container spacing={6} className="items-center">
            <Grid item xs={12} lg={6}>
              <div className="text-left px-4">
                <h2 className=" text-[22px] sm:text-5xl font-semibold text-gray-800">
                  Our Company
                </h2>
                <p className="text-lg mt-4 text-gray-600">
                  <strong>Get IT Solutions for</strong>
                </p>
                <div className="mt-6">
                  <h3 className="text-2xl font-bold text-gray-800">
                    Expert Consultants
                  </h3>
                  <p className="text-gray-600 mt-2">
                    Get expert IT solutions from seasoned consultants to
                    optimize your business operations. We provide excellent
                    services to elevate your business to the next level.
                  </p>
                </div>
                <div className="mt-6">
                  <h3 className="text-xl font-bold text-gray-800">
                    Professional Team
                  </h3>
                  <p className="text-gray-600 mt-2">
                    Codexialab's professional team excels in delivering
                    innovative solutions with expertise and dedication.
                  </p>
                </div>
                <div className="mt-6">
                  <h3 className="text-xl font-bold text-gray-800">
                    Managed IT Solutions
                  </h3>
                  <p className="text-gray-600 mt-2">
                    Our Managed IT Solutions offer comprehensive, reliable IT
                    support tailored to drive your business forward.
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className="px-4">
                <Lottie options={up} className="w-full h-auto rounded-lg" />
              </div>
            </Grid>
          </Grid>
        </div>

        {/* Section 2: Why Choose Us */}
        <div className="mb-16">
          <h2 className="text-3xl mb-16 text-center font-bold text-gray-800">
            Why Choose Us?
          </h2>
          <Grid container spacing={6} className="items-center">
            <Grid item xs={12} lg={6}>
              <div className="text-left px-4">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className="text-xl font-bold text-gray-800">
                      Optimized Solutions
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="text-gray-600">
                    Get tailored IT solutions from expert consultants who understand your business and help you perform at your best.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography className="text-xl font-bold text-gray-800">
                      24/7  Customer Solutions
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="text-gray-600">
                    24/7 customer support ensures you get assistance whenever you need it, keeping your business running smoothly.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography className="text-xl font-bold text-gray-800">
                      Digital Mindset
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="text-gray-600">
                      We approach every challenge with a digital-first mindset
                      to ensure cutting-edge solutions.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography className="text-xl font-bold text-gray-800">
                      Experienced Team 
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="text-gray-600">
                    CodexiaLab experienced team is dedicated to providing top-notch solutions and exceptional service tailored to your business needs.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="text-xl font-bold text-gray-800">
                      Everything on Time
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="text-gray-600">
                    CodexiaLab ensures that everything is delivered on time, so your business stays on track without delays.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className="px-4">
              <img src={CM} alt="Description of the image" />
              </div>
            </Grid>
          </Grid>
        </div>

        {/* Section 3: Stats Animation */}
       

        {/* Section 4: Free Consultation */}
        <div className="py-16 bg-green-100">
          <Grid container spacing={6} className="items-center">
            <Grid item xs={12} lg={6}>
              <div className="text-left px-4">
                <h2 className="text-3xl font-semibold text-gray-800">
                  Ready to Get Free Consultations for Any Kind of IT Solutions?
                </h2>
                <p className="text-lg mt-4 text-gray-600">
                  Get in touch with us for a free consultation to explore how we
                  can help you optimize your IT operations.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} lg={6} className="text-center">
              <Link to="/contact">
       <Button
          variant="contained"
         sx={{
        backgroundColor: "#059212",
        marginTop: "23px",
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: "#ffffff",
          color: "#000000",
          },
           }}
         className="py-2 px-6 text-lg text-white"
    >
        Contact Us
       </Button>
    </Link>
            </Grid>
          </Grid>
        </div>
      
     </Container>
     
    </section>
    <Footer/>
    </>
   
  );
};

export default About;
