import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";

const Navbar = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    window.scrollTo({ top: 0 }); // Scroll to the top immediately
    navigate(path); // Navigate to the specified path
  };

  return (
    <div className="hidden md:block">
      {/* Top Info Bar */}
      <div className="bg-slate-800 text-white py-3 flex justify-start w-full fixed top-0 z-50">
        <div className="ml-[73px] flex items-center">
          {/* Phone Info */}
          <PhoneEnabledOutlinedIcon className="text-green-500" />
          <span className="ml-1">+923036709775</span>
          {/* Email Info */}
          <span
            className="ml-[43px] flex items-center cursor-pointer"
            onClick={() => handleNavigation("")}
          >
            <MarkEmailReadOutlinedIcon className="text-green-500" />
            <span className="ml-2">info@codexialab.com</span>
          </span>
        </div>
      </div>

      {/* Navbar */}
      <nav className="bg-white text-green-400 shadow-md fixed top-[50px] w-full z-50">
        <div className="flex justify-between items-center px-3 md:px-7 py-6">
          {/* Brand Name */}
          <div className="text-2xl font-bold ml-5 md:ml-0">
            <span
              onClick={() => handleNavigation("/")}
              className="text-black cursor-pointer"
            >
              <span className="text-green-600 text-4xl">CodexiaLab</span>
            </span>
          </div>

          {/* Desktop Menu */}
          <div className="hidden lg:flex justify-center w-full space-x-12">
            {["/", "/ser", "/about", "/contact"].map((path, index) => (
              <span
                key={index}
                onClick={() => handleNavigation(path)}
                className="cursor-pointer flex items-center text-lg font-medium text-[18px] text-black hover:text-green-500 transition-colors relative group"
              >
                {["Home", "Services", "About", "Contact"][index]}
                <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-green-400 transition-all group-hover:w-full"></span>
              </span>
            ))}
          </div>

          {/* Consultation Button */}
          <div className="text-2xl font-bold mr-9 md:ml-0 hidden md:block">
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={() => handleNavigation("/contact")}
              className="hover:text-black transition-colors hover:bg-white"
              sx={{
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "white",
                  color: "green",
                },
              }}
            >
              Consultations
            </Button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
