import React, { useState } from "react";


import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix marker icon issue with Leaflet
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";

const Contact = () => {
  const [appointmentDetails, setAppointmentDetails] = useState({
    name: "",
    email: "",
    date: "",
    time: "",
  });

  const position = [31.5211, 74.3801]; // Coordinates for Saint Mary's Church, Gulberg 3, Lahore


  const customMarkerIcon = new L.Icon({
    iconUrl: markerIconPng,
    shadowUrl: markerShadowPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });


  return (
    <section className="py-20 bg-gray-100">
    <div className="container mx-auto">
       

        {/* Section 2: Map Section */}
        <div className="mb-[-70px] md:mb-[-120px]">
          <h2 className="text-3xl text-center font-bold text-gray-800 mb-8">
            Our Location
          </h2>
          <div className="relative h-96">
            <MapContainer
              center={position}
              zoom={15}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={position} icon={customMarkerIcon}>
                <Popup>
                  Codexialab Office <br /> Gulberg 3, Lahore.
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>

        {/* Remaining sections are unchanged */}
        {/* Appointment booking and contact form sections go here */}
      </div>
    </section>
  );
};

export default Contact;
