import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import "./styles.css"; // Importing custom styles
import p12 from "../image/p12.png";
import p11 from "../image/p11.png";
import p13 from "../image/p13.png";

const projectData = [
  {
    id: 1,
    title: "",
    image: p12,
    link: "https://thepennymatters.com/",
  },
  {
    id: 2,
    title: "",
    image: p11,
    link: "https://www.benchmarkone.com/",
  },
  {
    id: 3,
    title: "",
    image: p13,
    link: "https://www.captaincarwash.ae/",
  },
];

const ProjectCards = () => {
  return (
    <Box className="py-10 px-6 bg-gray-100">
      <Typography
        variant="h3"
        sx={{ marginBottom: "45px" }}
        className="text-center text-3xl font-black  text-gray-800"
      >
        Our Projects
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {projectData.map((project) => (
          <Grid item key={project.id}>
            <Box className="group card">
              <img
                src={project.image}
                alt={project.title}
                className="card-image"
              />
              <Box className="card-overlay">
                <Typography
                  variant="h6"
                  className="text-white text-lg font-semibold mb-2"
                >
                  {project.title}
                </Typography>
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="card-button"
                >
                  View Project
                </a>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProjectCards;
