import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import BookAppointment from "./pages/BookAppointment";
import Service from "./pages/Service";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Nav from "./Nav";
import Bottomnav from "./Bottomnav";
import Dashboard from "./Dashboard"; 
import AppBarWrapper from "./AppBarWrapper";

function App() {
  return (
    <Router>
      <AppBarWrapper />
      
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/BookAppointment" element={<BookAppointment />} />
        <Route path="/ser" element={<Service />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/nav" element={<Nav />} />
        <Route path="/janidb" element={<Dashboard />} />
        
        {/* Add the "Page Not Found" route */}
        <Route path="*" element={<h1>Page Not Found</h1>} />
        
        {/* Default Home Route */}
        <Route index element={<Home />} />
      </Routes>

      <Bottomnav />
    </Router>
  );
}

export default App;
