import React from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  MenuItem,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FaCalendarCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const pages = [
  { label: "Services", path: "/ser" },
  { label: "Contact Us", path: "/contact" },
  { label: "About", path: "/about" },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: "white",
        boxShadow: 3,
        zIndex: 1000,
        borderBottom: "2px solid #e0e0e0",
        display: { xs: "block", md: "none" }, // Visible only on screens smaller than md
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Left-aligned Title */}
          <Typography
            variant="h4"
            component="div"
            sx={{
              color: "#00a508",
              fontWeight: "bold",
              flexGrow: 1,
              textDecoration: "none",
            }}
          >
            CODEXIALAB
          </Typography>

          {/* Icon with hover effect and tooltip */}
          <Tooltip title="Book Appointment" arrow>
            <Link to="/BookAppointment" style={{ marginRight: "16px" }}>
              <FaCalendarCheck
                size={24}
                style={{ color: "#000", cursor: "pointer" }}
                onMouseOver={(e) => (e.target.style.color = "#059212")}
                onMouseOut={(e) => (e.target.style.color = "#000")}
              />
            </Link>
          </Tooltip>

          {/* Menu for Small Screens */}
          <Box>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.label}
                  component={Link}
                  to={page.path}
                  onClick={handleCloseNavMenu}
                >
                  {page.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </Box>
  );
}

export default ResponsiveAppBar;
