import React, { useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useNavigate } from "react-router-dom"; // React Router navigation hook
import { Paper } from "@mui/material";

// React Icons
import { AiOutlineHome } from "react-icons/ai";
import { BiBriefcase } from "react-icons/bi";
import { RiInformationLine } from "react-icons/ri";
import { MdContactMail } from "react-icons/md";

const Bottomnav = () => {
  const [value, setValue] = useState(0); // State to track the active tab
  const navigate = useNavigate(); // React Router navigate function

  const handleNavigation = (path, newValue) => {
    setValue(newValue); // Update active tab immediately
    navigate(path); // Navigate to the specified path
    window.scrollTo({ top: 0 }); // Scroll instantly to the top
  };

  return (
    <Paper elevation={27} style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1000 }}>
      <BottomNavigation
        value={value}
        showLabels
        sx={{
          display: { xs: "flex", md: "none" }, // Visible only on mobile screens
        }}
      >
        {/* Home Tab */}
        <BottomNavigationAction
          label="Home"
          icon={<AiOutlineHome size={24} />}
          onClick={() => handleNavigation("/", 0)}
          sx={{
            "&.Mui-selected": { color: "green" },
            "& .MuiBottomNavigationAction-label": { marginTop: "6px" },
          }}
        />

        {/* Services Tab */}
        <BottomNavigationAction
          label="Services"
          icon={<BiBriefcase size={24} />}
          onClick={() => handleNavigation("/ser", 1)}
          sx={{
            "&.Mui-selected": { color: "green" },
            "& .MuiBottomNavigationAction-label": { marginTop: "6px" },
          }}
        />

        {/* About Tab */}
        <BottomNavigationAction
          label="About"
          icon={<RiInformationLine size={24} />}
          onClick={() => handleNavigation("/about", 2)}
          sx={{
            "&.Mui-selected": { color: "green" },
            "& .MuiBottomNavigationAction-label": { marginTop: "8px" },
          }}
        />

        {/* Contact Tab */}
        <BottomNavigationAction
          label="Contact"
          icon={<MdContactMail size={24} />}
          onClick={() => handleNavigation("/contact", 3)}
          sx={{
            "&.Mui-selected": { color: "green" },
            "& .MuiBottomNavigationAction-label": { marginTop: "6px" },
          }}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default Bottomnav;
