import React, { useState } from "react";
import { Paper, TextField, Button, Grid } from "@mui/material";

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let isValid = true;
    let tempErrors = {};

    if (!formData.fullName) {
      isValid = false;
      tempErrors.fullName = "Full Name is required";
    }
    if (!formData.email) {
      isValid = false;
      tempErrors.email = "Email is required";
    }
    if (!formData.phone) {
      isValid = false;
      tempErrors.phone = "Phone Number is required";
    }
    if (!formData.subject) {
      isValid = false;
      tempErrors.subject = "Subject is required";
    }
    if (!formData.message) {
      isValid = false;
      tempErrors.message = "Message is required";
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch("https://www.codexialab.com/contact_us_api.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error("An error occurred while submitting the form.");
        }

        const result = await response.json();

        if (result.status === "success") {
          alert("Form submitted successfully!");
          setFormData({
            fullName: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        } else {
          alert(result.message || "Failed to submit the form.");
        }
      } catch (error) {
        alert(error.message);
        console.error("Error during form submission:", error);
      }
    }
  };

  return (
    <div className="mb-16">
      <h2 className="text-3xl text-center font-bold text-gray-800 mb-8">Contact Us</h2>
      <Paper className="p-8 shadow-lg rounded-lg max-w-3xl mx-auto">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                name="fullName"
                placeholder="Enter Name"
                value={formData.fullName}
                onChange={handleChange}
                error={!!errors.fullName}
                helperText={errors.fullName}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#059212",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#059212",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email Address"
                variant="outlined"
                fullWidth
                name="email"
                placeholder="Enter Email Address"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#059212",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#059212",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                name="phone"
                placeholder="Enter Phone Number"
                value={formData.phone}
                onChange={handleChange}
                error={!!errors.phone}
                helperText={errors.phone}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#059212",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#059212",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Subject"
                variant="outlined"
                fullWidth
                name="subject"
                placeholder="Enter Subject"
                value={formData.subject}
                onChange={handleChange}
                error={!!errors.subject}
                helperText={errors.subject}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#059212",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#059212",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                name="message"
                placeholder="Enter your message"
                value={formData.message}
                onChange={handleChange}
                error={!!errors.message}
                helperText={errors.message}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#059212",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#059212",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <div className="text-center mt-6">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#059212",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: "#046b0e",
                },
              }}
              type="submit"
            >
              Send Message
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default ContactUsForm;