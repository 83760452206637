import React, { useState } from "react";
import { Paper, TextField, Button, Grid } from "@mui/material";
import axios from "axios";

const BookAppointment = () => {
  const [appointmentDetails, setAppointmentDetails] = useState({
    name: "",
    email: "",
    date: "",
    time: "",
  });

  const currentDate = new Date();
  const currentTime = new Date(currentDate.getTime() + 60 * 60000);

  const formattedCurrentDate = currentDate.toISOString().split("T")[0];
  const formattedCurrentTime = currentTime.toISOString().split("T")[1].slice(0, 5);

  const handleAppointmentChange = (e) => {
    setAppointmentDetails({
      ...appointmentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleBookAppointment = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://www.codexialab.com/book_appointment.php",  // Ensure this is the correct backend URL
        appointmentDetails,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      // Check for success and display the appropriate message
      if (response.data.status === "success") {
        alert("Congratulations! Appointment is successful.");
        setAppointmentDetails({ name: "", email: "", date: "", time: "" });
      } else if (response.data.message) {
        // Display a generic error message without domain details
        alert(`Error: ${response.data.message}`);
      } else {
        alert("An unknown error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      alert("An error occurred while booking the appointment.");
    }
  };

  return (
    <div className="mb-16 mt-[80px] md:mt-[160px]">
      <h2 className="text-3xl text-center font-bold text-gray-800 mb-8">
        Book an Appointment
      </h2>
      <Paper className="p-8 shadow-lg rounded-lg max-w-3xl mx-auto">
        <form onSubmit={handleBookAppointment}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                name="name"
                value={appointmentDetails.name}
                onChange={handleAppointmentChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#059212",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#059212",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email Address"
                variant="outlined"
                fullWidth
                name="email"
                value={appointmentDetails.email}
                onChange={handleAppointmentChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#059212",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#059212",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Date"
                variant="outlined"
                type="date"
                fullWidth
                name="date"
                value={appointmentDetails.date}
                onChange={handleAppointmentChange}
                InputProps={{
                  inputProps: { min: formattedCurrentDate },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#059212",
                    },
                    "& input::-webkit-calendar-picker-indicator": {
                      color: "#059212",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#059212",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Time"
                variant="outlined"
                type="time"
                fullWidth
                name="time"
                value={appointmentDetails.time}
                onChange={handleAppointmentChange}
                InputProps={{
                  inputProps: { min: formattedCurrentTime },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#059212",
                    },
                    "& input::-webkit-calendar-picker-indicator": {
                      color: "#059212",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#059212",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <div className="text-center mt-6">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#059212",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: "#046b0e",
                },
              }}
              type="submit"
            >
              Book Appointment
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default BookAppointment;
