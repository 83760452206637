import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import Map from "./Map";
import BookAppointment from "./BookAppointment";
import ContactUsForm from "./ContactUsForm";
import Footer from "../Footer" ;



const Contact = () => {
  return (
    <>
    <section className="py-20 bg-gray-100 ">
      <div className=" mx-auto mt-[-50px] md:mt-[0px]">
        {/* Section 1: Contact Info Cards */}
        <div className="mb-16 text-center ">
          <h2 className="text-3xl  font-bold text-gray-800 mb-8">Contact Information</h2>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Card className="shadow-lg rounded-lg p-4">
                <CardContent>
                  <div className="flex items-center">
                    <FaEnvelope className="text-4xl text-blue-500 mr-4" />
                    <div>
                      <Typography variant="h6" className="font-semibold text-gray-800">
                        Email Address
                      </Typography>
                      <Typography variant="body1" className="text-gray-600">
                        info@codexialab.com
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Card className="shadow-lg rounded-lg p-4">
                <CardContent>
                  <div className="flex items-center">
                    <FaPhoneAlt className="text-4xl text-green-500 mr-4" />
                    <div>
                      <Typography variant="h6" className="font-semibold text-gray-800">
                        Phone Number
                      </Typography>
                      <Typography variant="body1" className="text-gray-600">
                        +923036709775
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Card className="shadow-lg rounded-lg p-4">
                <CardContent>
                  <div className="flex items-center">
                    <FaMapMarkerAlt className="text-4xl text-red-500 mr-4" />
                    <div>
                      <Typography variant="h6" className="font-semibold text-gray-800">
                        Office Address
                      </Typography>
                      <Typography variant="body1" className="text-gray-600">
                        Lahore, Gulberg
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        {/* Section 2: Map Section */}
        <Map />

        {/* Section 3: Book Appointment */}
        <BookAppointment />

        {/* Section 4: Contact Us Form */}

        <ContactUsForm />
        
     
      </div>
      
    </section>
    <Footer/>
    </>
    
  );
};

export default Contact;
