import React from "react";
import {  Link } from "react-router-dom";
import { Typography, Grid, Paper, Button, useMediaQuery, useTheme } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";

const WorkingProcess = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect small screens

  return (
    <section className="py-16 px-6 bg-white">
      {/* Title */}
<Typography
  variant={isMobile ? "h5" : "h4"}
  sx={{ textAlign: "center", color: "black", marginTop: "-15px", fontWeight: "bold", marginBottom: "25px" }}
>
  Our Working Process
</Typography>

{/* Subtitle */}
<Typography
  variant={isMobile ? "body2" : "body1"}
  sx={{ textAlign: "center", color: "black", marginTop: "15px", marginBottom: "34px" }}
>
  We follow a structured 4-step process to ensure that every project we undertake is a resounding success, delivering maximum value to your business and growth.
</Typography>


      {/* Timeline */}
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12} md={10}>
          <Timeline position={isMobile ? "right" : "alternate"}>
            {/* Step 1 */}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot variant="filled" style={{ backgroundColor: "#059212" }} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  elevation={12}
                  className="p-4 bg-white shadow-lg rounded-lg transform hover:scale-105 transition-all duration-300"
                  sx={{ textAlign: isMobile ? "center" : "left" }}
                >
                  <Typography variant="h5" className="font-semibold text-lg mb-2">
                    Step 1: Requirement Analysis
                  </Typography>
                  <Typography variant="body1" className="text-gray-700">
                    We start by fully understanding your business goals, pain points, and
                    needs, crafting a tailored solution that aligns with your objectives.
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>

            {/* Step 2 */}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot variant="filled" style={{ backgroundColor: "#059212" }} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  elevation={12}
                  className="p-4 bg-white shadow-lg rounded-lg transform hover:scale-105 transition-all duration-300"
                  sx={{ textAlign: "center" }}
                >
                  <Typography variant="h5" className="font-semibold text-lg mb-2">
                    Step 2: Planning
                  </Typography>
                  <Typography variant="body1" className="text-gray-700">
                    After understanding your requirements, we plan the entire project lifecycle,
                    defining tools, technologies, budget, and timeline for a successful delivery.
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>

            {/* Step 3 */}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot variant="filled" style={{ backgroundColor: "#059212" }} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  elevation={12}
                  className="p-4 bg-white shadow-lg rounded-lg transform hover:scale-105 transition-all duration-300"
                  sx={{ textAlign: isMobile ? "center" : "left" }}
                >
                  <Typography variant="h5" className="font-semibold text-lg mb-2">
                    Step 3: Development
                  </Typography>
                  <Typography variant="body1" className="text-gray-700">
                    Our team brings your vision to life through rigorous coding, testing, and
                    deployment, ensuring top-notch quality and performance.
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>

            {/* Step 4 */}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot variant="filled" style={{ backgroundColor: "#059212" }} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  elevation={12}
                  className="p-4 bg-white shadow-lg rounded-lg transform hover:scale-105 transition-all duration-300"
                  sx={{ textAlign: "center" }}
                >
                  <Typography variant="h5" className="font-semibold text-lg mb-2">
                    Step 4: Maintenance & Support
                  </Typography>
                  <Typography variant="body1" className="text-gray-700">
                    We ensure that the system runs smoothly post-launch with continuous
                    maintenance, updates, and improvements to meet evolving business needs.
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Grid>
      </Grid>

      {/* Call to Action */}
      <div className="text-center mt-12">
      <Link to="/contact"> 
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#059212",
            color: "white",
            px: 4,
            py: 1.5,
            fontSize: isMobile ? "0.875rem" : "1rem",
            transition: "all 0.3s ease", // Smooth animation for hover
            "&:hover": {
              backgroundColor: "#ffffff", // White background on hover
              color: "#000000",          // Black text on hover
            }
          }}
        >
          Get in Touch
        </Button>
    </Link> 
      </div>
    </section>
  );
};

export default WorkingProcess;
