import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Navbar from "./Navbar";
import ResponsiveAppBar from "./ResponsiveAppBar";
import Bottomnav from "./Bottomnav";

const AppBarWrapper = ({ children }) => {
  // Media query to check screen size
  const isMdOrAbove = useMediaQuery("(min-width: 768px)");

  // Adjust content margin based on navbar height
  const navbarHeight = isMdOrAbove ? "100px" : "56px"; // Example heights for desktop and mobile

  return (
    <>
      {/* Show Navbar for medium screens and above */}
      {isMdOrAbove ? <Navbar /> : <ResponsiveAppBar />}

      {/* Show Bottomnav for small screens */}
      {!isMdOrAbove && <Bottomnav />}

      {/* Add margin to prevent overlap */}
      <div style={{ marginTop: navbarHeight }}>{children}</div>
    </>
  );
};

export default AppBarWrapper;
