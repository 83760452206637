import React from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Grid, Typography } from "@mui/material";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scroll to the top
    navigate(path); // Navigate to the specified path
  };

  return (
    <Paper
      style={{
        backgroundColor: "#2f3640", // Slate-800 background color
        color: "white", // White text color
        paddingTop: "2.5rem", // py-10 equivalent for padding
        paddingBottom: "2.5rem",
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        {/* Contact Section */}
        <Grid item xs={12} sm={6} md={3} className="text-center md:text-left">
          <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "1rem" }}>
            Contact Us
          </Typography>
          <div className="flex items-center justify-center md:justify-start mb-2">
            <PhoneEnabledOutlinedIcon style={{ color: "#48bb78", marginRight: "0.5rem" }} />
            <span>+923036709775</span>
          </div>
          <div className="flex items-center justify-center md:justify-start">
            <MarkEmailReadOutlinedIcon style={{ color: "#48bb78", marginRight: "0.5rem" }} />
            <span>info@codexialab.com</span>
          </div>
        </Grid>

        {/* Quick Links Section */}
        <Grid item xs={12} sm={6} md={3} className="text-center md:text-left">
          <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "1rem" }}>
            Quick Links
          </Typography>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {[
              { label: "Home", path: "/" },
              { label: "About Us", path: "/about" },
              { label: "Services", path: "/ser" },
              { label: "Contact", path: "/contact" },
            ].map((link, index) => (
              <li key={index}>
                <span
                  onClick={() => handleNavigation(link.path)}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onMouseOver={(e) => (e.target.style.color = "#48bb78")}
                  onMouseOut={(e) => (e.target.style.color = "white")}
                >
                  {link.label}
                </span>
              </li>
            ))}
          </ul>
        </Grid>

        {/* Follow Us Section */}
        <Grid item xs={12} sm={6} md={3} className="text-center md:text-left">
          <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "1rem" }}>
            Follow Us
          </Typography>
          <div className="flex justify-center md:justify-start space-x-4">
            <a
              href="https://web.facebook.com/profile.php?id=61568853311604"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon
                style={{ color: "white", cursor: "pointer" }}
                onMouseOver={(e) => (e.target.style.color = "#48bb78")}
                onMouseOut={(e) => (e.target.style.color = "white")}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/codexialab"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon
                style={{ color: "white", cursor: "pointer" }}
                onMouseOver={(e) => (e.target.style.color = "#48bb78")}
                onMouseOut={(e) => (e.target.style.color = "white")}
              />
            </a>
            <a
              href="https://www.instagram.com/codexialab/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon
                style={{ color: "white", cursor: "pointer" }}
                onMouseOver={(e) => (e.target.style.color = "#48bb78")}
                onMouseOut={(e) => (e.target.style.color = "white")}
              />
            </a>
          </div>
        </Grid>
      </Grid>

      {/* Footer Bottom */}
      <div className="text-center mt-8">
        <Typography variant="body2" style={{ color: "#cbd5e0" }}>
          &copy; {new Date().getFullYear()} CodexiaLab. All Rights Reserved.
        </Typography>
      </div>
    </Paper>
  );
};

export default Footer;
