import React from 'react'

const Nav = () => {
  return (
    <>
        
    <div class="hidden ">
        <button>Menu Icon</button>
    </div>

    </>
  )
}
export default Nav;