import React from "react";
import {  Link } from "react-router-dom";
import { Paper, Button, Grid, Typography,  } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Workprocess from "./WorkingProcess";

import Lottie from 'react-lottie';
import group from '../image/group.json';
import man from '../image/man.json';
import react from '../image/react.jpg';
import seo from '../image/seo.jpg';
import wp from '../image/wp.jpg';
import cw from '../image/cw.jpg';
import ecom from '../image/ecom.jpg';
import Footer from "../Footer" ;
const Home = () => {
  const gp = {
    loop: true,  // Makes the animation loop
    autoplay: true,  // Makes the animation autoplay when the component is rendered
    animationData: group,  // This is where your animation JSON is loaded
};

const mn = {
  loop: true,  // Makes the animation loop
  autoplay: true,  // Makes the animation autoplay when the component is rendered
  animationData: man,  // This is where your animation JSON is loaded
};

  ;


  return (
<div className="font-sans">
{/* Part 1: Left text, right image, and button */}
<section className="flex flex-col md:flex-row items-center bg-white text-black py-16 px-6">
  
  <div className="md:w-1/2 ">
  <Typography
  variant="h3"
  sx={{
    fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem", lg: "3rem" },
    lineHeight: { xs: "1.2", sm: "1.4", md: "1.6" },
    marginBottom: { xs: "16px", md: "24px" },
    marginTop: { xs: "8px", sm: "16px", md: "24px", lg: "32px" },
    textAlign: "center",
    fontWeight: "bold",
    color: "inherit",
  }}
>
  Your Technology Partner for Innovative and Impactful Digital Solutions
</Typography>

    <Typography variant="h6" className="text-lg mb-8">
      At Codexialab, we help businesses leverage cutting-edge technology to enhance their growth and streamline operations. Our expert solutions are tailored to meet the unique needs of each client, ensuring that their digital transformation journey is a success.
    </Typography>

    {/* Consultation Button */}
    <div className="text-3xl font-bold mr-9 md:ml-0 mt-8 md:block">
      <Link to="/BookAppointment">
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          sx={{
            backgroundColor: "#059212",
            transition: "all 0.3s ease", // Smooth animation for hover
            "&:hover": {
              backgroundColor: "#ffffff", // White background on hover
              color: "#000000",          // Black text on hover
            },
          }}
          className="text-white"
        >
          Book Appointment
        </Button>
      </Link>
    </div>
  </div>

  <div className="md:w-1/2 mt-8 md:mt-0">
  <Lottie options={mn} className="w-full h-auto rounded-lg" />
</div>

</section>


 {/* Part 2: Image on the left, text on the right */}
 <section className="flex flex-col-reverse md:flex-row items-center py-16 px-6">
  <div className="md:w-1/2 mt-8 md:mt-0">
    <Lottie options={gp} className="w-full h-auto rounded-lg" />
  </div>

  <div className="md:w-1/2 text-center md:text-left mt-8 md:mt-0">
    <Typography
      variant="h6"
      sx={{
        marginBottom: { xs: "16px", md: "24px" },
        marginTop: { xs: "-56px", md: "-94px lg:-145px" },
        color: "#059212",
        textAlign: "start",
      }}
      className="text-3xl font-bold mb-4"
    >
      What We Do
    </Typography>
    <Typography
      variant="h2"
      sx={{
        marginBottom: { xs: "16px", md: "24px" },
        textAlign: "start",
      }}
      className="text-2xl font-black mb-4"
    >
      Digital Solution Consulting For Your Business
    </Typography>
    <Typography
      variant="h6"
      sx={{
        marginBottom: { xs: "16px", md: "24px" },
        textAlign: "start",
      }}
      className="text-lg mb-6"
    >
      Codexialab is the partner of choice for many of the world’s leading enterprises and SMEs, helping them elevate their value through innovative digital solutions.
      <ul className="list-none pl-6 text-lg space-y-2 mt-4">
        <li className="flex items-center">
          <span
            className="flex justify-center items-center w-6 h-6 rounded-full bg-[#059212] text-white mr-3"
          >
            ✓
          </span>
          SEO Services
        </li>
        <li className="flex items-center">
          <span
            className="flex justify-center items-center w-6 h-6 rounded-full bg-[#059212] text-white mr-3"
          >
            ✓
          </span>
          Content Writing
        </li>
        <li className="flex items-center">
          <span
            className="flex justify-center items-center w-6 h-6 rounded-full bg-[#059212] text-white mr-3"
          >
            ✓
          </span>
          WordPress Development
        </li>
        <li className="flex items-center">
          <span
            className="flex justify-center items-center w-6 h-6 rounded-full bg-[#059212] text-white mr-3"
          >
            ✓
          </span>
          React E-commerce Solutions
        </li>
        <li className="flex items-center">
          <span
            className="flex justify-center items-center w-6 h-6 rounded-full bg-[#059212] text-white mr-3"
          >
            ✓
          </span>
          Custom Web Development
        </li>
      </ul>
    </Typography>
    <Link to="/ser">
       <Button
          variant="contained"
         sx={{
        backgroundColor: "#059212",
        marginTop: "23px",
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: "#ffffff",
          color: "#000000",
          },
           }}
         className="py-2 px-6 text-lg text-white"
    >
        Know More About Us
       </Button>
    </Link>
  </div>
</section>


 {/* Part 3: Our Services */}
<section className="bg-gray-100 py-16 px-6">
<Typography
  variant="h2"
  sx={{ marginBottom: "24px" }}
  className="text-center text-3xl font-black"
>
  Our Services
</Typography>
<Typography
  variant="h5"
  sx={{ marginTop: "16px", marginBottom: "48px" }}
  className="text-center"
>
  Business growth and software optimization go hand in hand. Our services are designed to elevate your business to the next level with customized solutions.
</Typography>

<Grid container spacing={4} justifyContent="center">
    <Grid item xs={12} sm={6} lg={4}>
      <Paper className="p-6 hover:shadow-xl transition-shadow duration-300">
        <img src={react} alt="Service 1" className="w-full h-50 object-cover mb-4" />
        <Typography variant="h5" className="font-semibold text-xl mb-2">React E-commerce Solutions</Typography>
        <Typography variant="body1" className="text-gray-700">
        Build fast, scalable, and efficient e-commerce websites using React. 
        We create customized online stores that enhance user experience.
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6} lg={4}>
      <Paper className="p-6 hover:shadow-xl transition-shadow duration-300">
        <img src={seo} alt="Service 2" className="w-full h-50 object-cover mb-4" />
        <Typography variant="h5" className="font-semibold text-xl mb-2">SEO Services</Typography>
        <Typography variant="body1" className="text-gray-700">
        Improve your website's visibility with our expert SEO services. 
        We optimize your content to rank higher in search engines.
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6} lg={4}>
      <Paper className="p-6 hover:shadow-xl transition-shadow duration-300">
        <img src={cw} alt="Service 3" className="w-full h-50 object-cover mb-4" />
        <Typography variant="h5" className="font-semibold text-xl mb-2">Content Writing</Typography>
        <Typography variant="body1" className="text-gray-700">
        We offer professional content writing services to engage your audience 
        and provide valuable information that resonates with your brand.
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6} lg={4}>
      <Paper className="p-6 hover:shadow-xl transition-shadow duration-300">
        <img src={ecom} alt="Service 4" className="w-full h-50 object-cover mb-4" />
        <Typography variant="h5" className="font-semibold text-xl mb-2">Elevate Your Online Store</Typography>
        <Typography variant="body1" className="text-gray-700">
        Modern, responsive, and user-friendly e-commerce solutions.
        </Typography>
      </Paper>
    </Grid>
    
    <Grid item xs={12} sm={6} lg={4}>
      <Paper className="p-6 hover:shadow-xl transition-shadow duration-300">
        <img src={wp} alt="Service 5" className="w-full h-50 object-cover mb-4" />
        <Typography variant="h5" className="font-semibold text-xl mb-2">WordPress Development</Typography>
        <Typography variant="body1" className="text-gray-700">
          We provide expert WordPress development services to build scalable and user-friendly.
        </Typography>
      </Paper>
    </Grid>
  </Grid>
</section>



      {/* Part 4: Our Working Process */}
      {/* <section className="py-16 px-6 bg-gray-200">
        <Typography variant="h4" className="text-center text-3xl font-bold mb-8">
          Our Working Process
        </Typography>
        <Typography variant="body1" className="text-center mb-12">
          We follow a 4-step process to ensure the success of every project and help your business grow.
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={3}>
            <Paper className="p-6 bg-white hover:shadow-xl transition-shadow duration-300">
              <Typography variant="h6" className="text-xl font-semibold mb-4">Step 1: Requirement Analysis</Typography>
              <Typography variant="body2" className="text-gray-700 mb-4">
                Understanding your business goals and challenges to tailor the project scope and objectives.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper className="p-6 bg-white hover:shadow-xl transition-shadow duration-300">
              <Typography variant="h6" className="text-xl font-semibold mb-4">Step 2: Planning</Typography>
              <Typography variant="body2" className="text-gray-700 mb-4">
                Identifying the tools, technologies, timeline, and budget to ensure a successful project delivery.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper className="p-6 bg-white hover:shadow-xl transition-shadow duration-300">
              <Typography variant="h6" className="text-xl font-semibold mb-4">Step 3: Development</Typography>
              <Typography variant="body2" className="text-gray-700 mb-4">
                Coding, testing, and deploying the project based on the plan, with continuous improvements.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper className="p-6 bg-white hover:shadow-xl transition-shadow duration-300">
              <Typography variant="h6" className="text-xl font-semibold mb-4">Step 4: Maintenance & Support</Typography>
              <Typography variant="body2" className="text-gray-700 mb-4">
                Ensuring the project is running smoothly with updates, bug fixes, and improvements as required.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </section> */}
      <Workprocess />
      <Footer/>
     
    </div>
  );
};

export default Home;